import React from 'react';
import styled from 'styled-components';

import { Input, H3, SubTitle, screenSize } from '../../index';
import { InstaLogo, FaceLogo } from '../../images';

export const StayConnected = ({ header, rtl }) => (
  <Wrapper>
    <H3>{header}</H3>
    <Container data-rtl={rtl}>
      <InputWrapper>
        <Input rtl={rtl} />
      </InputWrapper>
      <Social href="https://www.instagram.com/Leem_global/" target="_blank">
        <InstaLogo />
        <SubTitle data-size="s">
          {rtl ? 'ليم-العالمية' : 'Leem_global'}
        </SubTitle>
      </Social>
      <Social
        href="https://www.facebook.com/pages/category/Clothing-Store/Leem_global-2107720136164214/"
        target="_blank"
      >
        <FaceLogo />
        <SubTitle data-size="s">
          {rtl ? 'ليم-العالمية' : 'Leem_global'}
        </SubTitle>
      </Social>
    </Container>
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 6vw 7.5vw;

  h3 {
    margin-bottom: 2vw;
  }
`;

const Social = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: var(--black);

  :hover {
    svg {
      opacity: 0.8;
    }
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  justify-items: start;

  &[data-rtl='true'] {
    direction: ltr;
  }

  @media screen and (max-width: ${screenSize.m}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  svg {
    max-width: 3vw;
    margin-right: 1vw;

    @media screen and (max-width: ${screenSize.m}) {
      max-width: 24px;
    }
  }
`;

const InputWrapper = styled.div`
  min-width: 100%;
  padding-right: 25%;

  @media screen and (max-width: ${screenSize.m}) {
    grid-column: 1/3;
    padding-right: 0;
    margin: 3vw 0;
  }
`;
